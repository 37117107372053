import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AgendamentoContext } from '../../../contexts/Agendamento/AgendamentoContext';
import { PreAgendamentoContext } from '../../../contexts/PreAgendamento/PreAgendamentoContext';
import { ApplicationState } from '../../../store';
import { PreAgendamentosState } from '../../../store/ducks/preAgendamento/types';
import { DadosDoAgendamento, INotaFiscal, IResponseAgendamento, IResponseDatasPercentual } from '../../../types/Agendamentos/agendamento';
import { DadosDoPreAgendamentoFormNotas, IPreAgendamentoNotasForm, PreAgendamentoNotasRequest, PreAgendamentoRowResponse } from '../../../types/PreAgendamentos/preAgendamento';
import { getCurrentUserValues } from '../../../utils/auth';
import { filtroDeDatasDesejadas, filtroDeDatasDisponiveis, removeDuplicatesStrings } from '../../../utils/lists';
import { filterToOnlyNumbers, formatCNPJ, readFileAsText } from '../../../utils/strings';
import { FormFieldTypesEnum } from '../../Forms/render';
import { StyledForm, StyledFormFooter } from "../../Forms/styles";
import { DateType, InputType, SelectType } from '../../Forms/types';
import { StyledButton } from '../../UI/buttons';
import { StyledContainerForResponsive } from '../../UI/containers';
import { StyledSearchIcon } from '../../UI/icons';
import { StyledTitleCharcoal } from '../../UI/titles';
import { formatStringToArray, mergeNotaFiscais } from '../../_Agendamentos/AgendamentoForm/service';
import TableChavesDeAcesso from '../../_Agendamentos/Tables/TableChavesAcesso';
import { enumMessagesAgendamento, enumMotivoBloqueioNotaFiscal } from '../../_Agendamentos/service';
import { FormDadosEChavesDeAcessoPreAgendamento, PreAgendamentoNotesSchema } from './schema';
import { StyledContainerKeys } from '../../_Agendamentos/AgendamentoForm/styles';
import { EnumCD } from '../TablePreAgendamentos/enum';

moment.locale("pt-br");

export default function PreAgendamentoNotasForm() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const decodedToken = getCurrentUserValues();
    const [tipoFrete, setTipoFrete] = useState('CIF');
    const [cdDestino, setCdDestino] = useState(1021);
    const [filesXml, setFilesXml] = useState<File[]>([]);
    const [chavesString, setChavesString] = useState<string>("");
    const [dataDeEntrega, setDataDeEntrega] = useState<Date | null>(null);
    const [datasDisponiveis, setDatasDisponiveis] = useState<IResponseDatasPercentual[]>([]);
    const [dataDesejadaParaEntrega, setDataDesejadaParaEntrega] = useState<Date | null>(null);
    const { setTicket, setListaDeChaves, listaDeChaves, setListaDeNotasFiscais, listaDeNotasFiscais } = useContext(AgendamentoContext);
    const { preAgendamentoContext, setPreAgendamentoContext } = useContext(PreAgendamentoContext);
    const { loading } = useSelector<ApplicationState, PreAgendamentosState>((state) => state?.preAgendamentos);
    const uncheckedKeysLength = listaDeNotasFiscais?.filter((nota: INotaFiscal) => nota?.motivoBloqueio).length;

    const {
        register: registerFormDadosEChavesDeAcesso,
        handleSubmit: handleSubmitFormDadosEChavesDeAcesso,
        formState: { errors: errorsFormDadosEChavesDeAcesso },
        control: controlFormDadosEChavesDeAcesso,
        setValue: setValueFormDadosEChavesDeAcesso,
        watch: watchFormDadosEChavesDeAcesso,
        reset: resetFormDadosEChavesDeAcesso,
    } = useForm<DadosDoPreAgendamentoFormNotas>({ resolver: yupResolver(FormDadosEChavesDeAcessoPreAgendamento) });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch,
        reset,
    } = useForm<IPreAgendamentoNotasForm>({ resolver: yupResolver(PreAgendamentoNotesSchema) });

    const { chavesDeAcesso, cnpj, email, nomeTransportadora, quantidadeDeVeiculos, telefone, motorista } = watchFormDadosEChavesDeAcesso();
    const { volume, sku, skuAnterior, volumeAnterior } = watch();

    useEffect(() => {
        if (!preAgendamentoContext.ticket) navigate('/pre-agendamentos');
    }, [preAgendamentoContext])

    useEffect(() => {
        completeDadosForm(preAgendamentoContext);
        return () => handleBeforeUnload();
    }, []);

    useEffect(() => {
        handleFileInputXml();
    }, [filesXml])

    useEffect(() => {
        if (listaDeNotasFiscais?.length) {
            const listaFormatada = listaDeNotasFiscais?.map((nota: INotaFiscal) => nota?.chave.trim()).join("\n");
            setValue("chavesAcesso", listaFormatada);
            setValueFormDadosEChavesDeAcesso('chavesDeAcesso', listaFormatada);
        }
    }, [listaDeNotasFiscais])

    const getKeyFromValue = (value: number) => {
        return Number(Object.entries(EnumCD).find(([key, val]) => val === value.toString())?.[0] || 0);
      }

    const completeDadosForm = (preAgendamento: PreAgendamentoRowResponse) => {
        setValueFormDadosEChavesDeAcesso('cnpj', formatCNPJ(preAgendamento?.cnpjTransportadora).padStart(14, '0'));
        setValueFormDadosEChavesDeAcesso('nomeTransportadora', preAgendamento?.nomeTransportadora);
        setValueFormDadosEChavesDeAcesso('email', decodedToken?.email);
        setValueFormDadosEChavesDeAcesso('cdDestino', preAgendamento?.cd);
        setValueFormDadosEChavesDeAcesso('quantidadeDeVeiculos', 1);
        setValueFormDadosEChavesDeAcesso('telefone', decodedToken?.fone);
        setValueFormDadosEChavesDeAcesso('tipoFrete', preAgendamento.frete || 'CIF');
        setValue('skuAnterior', preAgendamento?.quantidadeSku);
        setValue('volumeAnterior', preAgendamento?.volumes);
        setValue('data', moment(preAgendamento?.dataAgendamento).format("YYYY-MM-DD"));
        setValue('dataDesejada', moment(preAgendamento?.dataDesejada).format("YYYY-MM-DD"));
        setDataDeEntrega(moment(preAgendamento?.dataAgendamento).utc().toDate());
        setDataDesejadaParaEntrega(moment(preAgendamento?.dataDesejada).utc().toDate());
        setCdDestino(getKeyFromValue(preAgendamento?.cd));
    }

    const handleBeforeUnload = () => {
        setDatasDisponiveis([]);
        setDataDeEntrega(null);
        setDataDesejadaParaEntrega(null);
        setListaDeChaves([]);
        setChavesString("");
        setListaDeNotasFiscais([]);
        setFilesXml([]);
        resetFormDadosEChavesDeAcesso();
        reset();
        setPreAgendamentoContext({} as PreAgendamentoRowResponse);
    };

    const handleChavesDeAcesso = (chaves: string) => {
        const chavesArray = formatStringToArray(chaves);
        const keysList = chavesArray?.map((key) => key.trim());
        const uniqueKeysList = removeDuplicatesStrings(listaDeChaves, keysList);
        setListaDeChaves(uniqueKeysList);
        setValueFormDadosEChavesDeAcesso("chavesXml", []);
        setValueFormDadosEChavesDeAcesso("chavesDeAcesso", '');
        setFilesXml([]);
    };

    const moveToBottom = () => setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);

    const handleFileInputXml = async () => {
        let list: string = ""
        if (!filesXml) return;
        if (filesXml?.length) {
            const listaDeChavesDoXml: string[] = [];
            for (const file of filesXml) {
                list = list + await readFileAsText(file);
            }
        }
        setChavesString(list);
        setValueFormDadosEChavesDeAcesso("chavesDeAcesso", list);
    };

    const onSuccessHandleCnpj = (transportadora: any) => {
        setValueFormDadosEChavesDeAcesso("nomeTransportadora", transportadora?.descricao);
        setValueFormDadosEChavesDeAcesso("cnpj", formatCNPJ(transportadora?.cnpj));
        toast.success(enumMessagesAgendamento.TRANSPORTADORA_ENCONTRADA);
    }

    const onInvalidCnpj = (message?: string) => {
        if (message) toast.error(message);
        setValueFormDadosEChavesDeAcesso("cnpj", formatCNPJ(decodedToken?.cnpj || ""));
        setValueFormDadosEChavesDeAcesso("nomeTransportadora", decodedToken?.name || "");
    }

    const handleCnpj = async () => {
        const pureCnpj = watchFormDadosEChavesDeAcesso("cnpj");
        const cnpj = filterToOnlyNumbers(pureCnpj);
        if (cnpj?.length === 14 && !loading && cnpj !== decodedToken?.cnpj) {
            dispatch({
                type: '@agendamentos/GET_TRANSPORT_DETAILS',
                payload: { cnpj },
                onSuccess: onSuccessHandleCnpj,
                onError: onInvalidCnpj
            })
        } else onInvalidCnpj('CNPJ inválido');
    }

    const errorsKeysTreatment = (resp: any) => {
        if (resp.notasInvalidas?.some((nota: INotaFiscal) => nota?.motivoBloqueio === enumMotivoBloqueioNotaFiscal.CD_INCORRETO)) toast.error(enumMessagesAgendamento.CHAVES_CD_INCORRETO);
        if (resp.notasInvalidas?.some((nota: INotaFiscal) => nota?.motivoBloqueio === enumMotivoBloqueioNotaFiscal.AGENDADA)) toast.error(enumMessagesAgendamento.CHAVES_JA_AGENDADAS);
        if (resp.notasInvalidas?.some((nota: INotaFiscal) => nota?.motivoBloqueio === enumMotivoBloqueioNotaFiscal.NAO_LOCALIZADA)) toast.error(enumMessagesAgendamento.CHAVES_NAO_LOCALIZADAS);
        if (resp.notasInvalidas?.length) toast.error(enumMessagesAgendamento.CHAVES_REMOVE_INVALIDAS);
        if (!resp?.notasInvalidas?.length && resp?.notasValidas?.length) toast.success(enumMessagesAgendamento.CHAVES_VERIFICADAS);
    };

    const chavesValidadasComSucesso = (resp: any) => {
        const notasFiscaisRetorno = [...(resp?.notasInvalidas ?? []), ...(resp?.notasValidas ?? [])];
        setListaDeNotasFiscais(mergeNotaFiscais(listaDeNotasFiscais, notasFiscaisRetorno));
        errorsKeysTreatment(resp);
        if (!resp?.notasInvalidas?.length) {
            moveToBottom();
        }
        setValueFormDadosEChavesDeAcesso('chavesDeAcesso', '');
        setValueFormDadosEChavesDeAcesso('chavesXml', []);
    }

    const aoFalharValidacaoDeChaves = () => {
        const element = document.getElementById("filesXml") as any;
        element?.setAttributeNode(document.createAttribute("files"));
        document.createAttribute("files")
        if (element?.files) element.files = null;
    }

    const onSubmitDadosEChaves: SubmitHandler<DadosDoAgendamento> = data => {
        const arrayAsString = listaDeChaves?.map((item: string, index: number) => {
            if (index === 0) return "?chave=" + item;
            return "&chave=" + item;
        }).join("");

        if (!loading && cdDestino > 0 && listaDeChaves?.length) {
            dispatch({
                type: "@agendamentos/GET_VERIFY_KEYS",
                payload: {
                    cd: cdDestino,
                    chaves: arrayAsString || "",
                    cnpj: filterToOnlyNumbers(data.cnpj),
                    nomeTransportadora: data.nomeTransportadora,
                },
                onSuccess: (resp: any) => chavesValidadasComSucesso(resp),
                onError: () => aoFalharValidacaoDeChaves()
            })
        }
    }

    const onSuccessConfirmPreAgendamento = (res: IResponseAgendamento) => {
        if (res?.ticket) {
            toast.success("Agendamento criado com sucesso!")
            dispatch({ type: "@preAgendamentos/GET_PRE_AGENDAMENTOS" })
            dispatch({  type: "@agendamentos/GET_AGENDAMENTOS", })
            setTicket(res);
            navigate('/ticket')
        }
    }

    const mapperToPreAgendamentoRequest = (dataComming: IPreAgendamentoNotasForm): PreAgendamentoNotasRequest => {
        return {
            // dataDesejada: moment(dataComming?.dataDesejada).format("YYYY-MM-DD"),
            // data: moment(dataComming?.data).format("YYYY-MM-DD"),
            transportadora: nomeTransportadora,
            frete: tipoFrete,
            email,
            cnpj: filterToOnlyNumbers(cnpj)?.toString(),
            cd: cdDestino,
            codigo: Number(preAgendamentoContext?.ticket),
            quantidadeSku: dataComming?.sku,
            volume: dataComming?.volume,
            motorista,
            quantidadeVeiculos: quantidadeDeVeiculos,
            telefone: Number(telefone?.replace(/\D/g, '')),
            justificativa: dataComming?.justificativa,
            chaves: dataComming?.chavesAcesso?.split("\n"),
        }
    }

    const onSubmitDatas: SubmitHandler<IPreAgendamentoNotasForm> = (dataComming) => {
        const request = mapperToPreAgendamentoRequest(dataComming);

        if (uncheckedKeysLength) toast.error("Você precisa validar todas as chaves antes de finalizar o pré-agendamento!");
        else {
            dispatch({
                type: "@preAgendamentos/POST_CONFIRMA_PRE_AGENDAMENTO",
                payload: request,
                onSuccess: onSuccessConfirmPreAgendamento
            })
        }
    }

    const INPUT_SKU: InputType[] = [
        {
            label: 'SKU',
            type: 'number',
            name: 'sku',
            register,
            errors,
            placeholder: 'Digite o SKU',
        },
    ]
    const INPUT_VOLUME: InputType[] = [
        {
            label: 'Número de caixas',
            type: 'number',
            name: 'volume',
            register,
            errors,
            placeholder: 'Digite o número de caixas do agendamento',
        },
    ]
    const DATE_PICKER: DateType[] = [
        {
            disabled: true,
            label: 'Data que você gostaria que fosse possivel agendar:',
            name: 'dataDesejada',
            register,
            title: 'Não é possível alterar a data.',
            errors,
            control,
            filterDate: filtroDeDatasDesejadas,
            onChange: (date: any) => {
                if (date) {
                    setValue('dataDesejada', date)
                    setDataDesejadaParaEntrega(date)
                }
            },
            value: dataDesejadaParaEntrega,
        },
        {
            disabled: true,
            label: 'Datas da entrega:',
            name: 'data',
            title: 'Não é possível alterar a data.',
            register,
            errors,
            control,
            filterDate: (value) => filtroDeDatasDisponiveis(value, datasDisponiveis.map(date => date.data)),
            onChange: (date: any) => {
                if (date) {
                    setValue('data', date)
                    setDataDeEntrega(date)
                }
            },
            value: dataDeEntrega,
        },
    ]
    const INPUT_SKU_ANTERIOR: InputType[] = [
        {
            label: 'SKU Anterior',
            type: 'text',
            name: 'skuAnterior',
            disabled: true,
            register,
            errors,
        },
    ]
    const INPUT_VOLUME_ANTERIOR: InputType[] = [
        {
            label: 'Número de caixas Anterior',
            type: 'text',
            name: 'volumeAnterior',
            disabled: true,
            register,
            errors,
        },
    ]
    const INPUTS_PRE_AGENDAMENTO: InputType[] = [
        {
            disabled: true,
            label: 'CNPJ',
            type: 'text',
            name: 'cnpj',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'Digite o CNPJ',
            onBlur: async () => await handleCnpj(),
            onKeyPress: (e: any) => e?.target?.blur(),
            componentInside: (<StyledSearchIcon style={{ fontSize: "2.0rem" }} id="search-icon" />)
        },
        {
            label: 'Nome da Transportadora',
            type: 'text',
            name: 'nomeTransportadora',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'Nome da Transportadora',
            onChange: (e: any) => {
                setValueFormDadosEChavesDeAcesso('nomeTransportadora', e.target.value)
            },
            disabled: true
        },
        {
            label: 'Fornecedor / Transportador',
            type: 'email',
            name: 'email',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'email@email.com',
            disabled: true
        },
        {
            label: 'Motorista',
            type: 'text',
            name: 'motorista',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'Digite o nome do motorista'
        },
        {
            label: 'Veículos',
            type: 'text',
            name: 'quantidadeDeVeiculos',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'Quantidade de veículos envolvidos no transporte',
        },
        {
            label: 'Telefone',
            type: 'text',
            name: 'telefone',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'Digite o telefone de contato',
            disabled: true,
        },
    ]
    const SELECT_PRE_AGENDAMENTO: SelectType[] = [
        {
            label: "Frete",
            name: "tipoFrete",
            options: [
                { key: "CIF", value: "CIF", label: "CIF" },
                { key: "FOB", value: "FOB", label: "FOB" },
            ],
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            control: controlFormDadosEChavesDeAcesso,
            defaultValue: tipoFrete,
            onChange: (value: string) => {
                setTipoFrete(value)
                setValueFormDadosEChavesDeAcesso('tipoFrete', value)
            },
            value: tipoFrete,
        },
        {
            label: "Centro de Distribuição de Destino",
            name: "cdDestino",
            isReadOnly: Boolean(true),
            options: [
                { key: "PR-1017", value: 1017, label: "PR-1017" },
                { key: "RS-1021", value: 1021, label: "RS-1021" },
                { key: "ES-1007", value: 1007, label: "ES-1007" },
            ],
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            control: controlFormDadosEChavesDeAcesso,
            defaultValue: cdDestino,
            onChange: (value: number) => {
                setValueFormDadosEChavesDeAcesso('cdDestino', value)
                setCdDestino(value)
                const buttonVerificarChaves = document.getElementById('button-form-chaves');
                if (buttonVerificarChaves) {
                    setTimeout(() => buttonVerificarChaves.click(), 100);
                }
            },
            value: cdDestino,
        },
    ]
    const INPUT_KEYS: InputType[] = [
        {
            label: 'Chaves de Acesso',
            type: 'text',
            name: 'chavesDeAcesso',
            register: registerFormDadosEChavesDeAcesso,
            errors: errorsFormDadosEChavesDeAcesso,
            placeholder: 'Digite as chaves de acesso separadas uma por linha'
        },
    ]
    const FILE_UPLOAD: InputType[] = [
        {
            label: 'Arquivo XML',
            name: 'filesXml',
            errors: errorsFormDadosEChavesDeAcesso,
            register: registerFormDadosEChavesDeAcesso,
            type: 'file',
            empty: filesXml.length == 0,
            onChange: (event) => setFilesXml(event.target.files),
        },
    ]
    const JUSTIFY_TEXT: InputType[] = [{
        label: 'Motivo do envio de menos número de caixas ou SKU que o pré-agendado:',
        type: 'text',
        name: 'justificativa',
        register,
        errors,
        placeholder: '',
    }]

    const renderFormChavesDeAcesso = () => {
        return (
            <>
                <Typography variant="h5" className="mt-4 text-blue">1 - Dados da Transportadora:</Typography>
                <StyledForm
                    id='form-pre-agendamento'
                    onSubmit={handleSubmitFormDadosEChavesDeAcesso(onSubmitDadosEChaves)}
                >
                    {FormFieldTypesEnum.INPUT(INPUTS_PRE_AGENDAMENTO)}
                    {FormFieldTypesEnum.SELECT(SELECT_PRE_AGENDAMENTO)}
                </StyledForm>
                <hr />
                <Typography variant="h5" className="mt-2 text-blue">2 - Chaves de Acesso:</Typography>
                <Typography variant="subtitle1" className="mt-4">Adicione sua(s) chave(s) ou importe arquivos de tipo XML:</Typography>
                <StyledForm
                    id='form-pre-agendamento'
                    onSubmit={handleSubmitFormDadosEChavesDeAcesso(onSubmitDadosEChaves)}
                >
                    {FormFieldTypesEnum.INPUT(INPUT_KEYS)}
                    {FormFieldTypesEnum.INPUT_UPLOAD_XML(FILE_UPLOAD)}
                    {errors?.chavesAcesso?.message && <span className="error-message m-0">{errors?.chavesAcesso?.message}</span>}
                </StyledForm>
                <StyledContainerKeys>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        className="mt-2 mb-2"
                        disabled={loading}
                        onClick={() => {
                            handleChavesDeAcesso(chavesString || chavesDeAcesso)
                        }}
                    >Adicionar Chave(s) de Acesso(s)
                    </StyledButton>
                    <TableChavesDeAcesso />
                </StyledContainerKeys>
                {listaDeChaves?.length ? (
                    <StyledFormFooter className="mt-4">
                        <StyledButton
                            id="button-form-chaves"
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={loading || listaDeChaves.length < 1 && !listaDeNotasFiscais?.every(nota => nota.motivoBloqueio != enumMotivoBloqueioNotaFiscal.NAO_VERIFICADA)}
                            form='form-pre-agendamento'
                        >Enviar chaves para verificação
                        </StyledButton>
                    </StyledFormFooter>) : <></>}
            </>
        )
    }

    const hasDifferenceByValues = () => Boolean(skuAnterior && sku && sku < skuAnterior || volumeAnterior && volume && volume < volumeAnterior);

    const renderJustifyVolumeSku = () => {
        return (
            <>
                {hasDifferenceByValues() && <Typography variant="h5" className="mt-4 text-blue">4- Justificativa:</Typography>}
                {hasDifferenceByValues() && FormFieldTypesEnum.TEXT_AREA(JUSTIFY_TEXT)}
            </>)
    }

    const renderFormVolumesESkus = () => {
        return (<>
            <Typography variant="h5" className="mt-4 text-blue">3 - Número de caixas e SKU:</Typography>
            <StyledForm
                id="form-pre-agendamento-volume"
                className="volumes-skus"
            >
                {FormFieldTypesEnum.INPUT(INPUT_VOLUME_ANTERIOR)}
                {FormFieldTypesEnum.INPUT(INPUT_SKU_ANTERIOR)}
                {FormFieldTypesEnum.INPUT(INPUT_VOLUME)}
                {FormFieldTypesEnum.INPUT(INPUT_SKU)}
            </StyledForm>

        </>)
    }

    const renderDateForm = () => {
        return (
            <>
                <Typography variant="h5" className="mt-4 text-blue">{hasDifferenceByValues() ? "5" : "4"} - Data de Entrega:</Typography>
                <StyledForm
                    lang='pt-br'
                    id='form-pre-agendamento-datas'
                    className=''
                    name='form-pre-agendamento-datas'>
                    {FormFieldTypesEnum.DATE(DATE_PICKER)}
                    <div className="form-footer">
                        <StyledButton
                            id="button-form-pre-agendamento-datas"
                            type="submit"
                            color="primary"
                            variant="contained"
                            sx={{ width: '400px !important' }}
                            className="mt-4 mb-4"
                            form="form-pre-agendamento-datas"
                            title="Finalizar pré-agendamento"
                            disabled={Boolean(loading || uncheckedKeysLength)}
                            onClick={handleSubmit(onSubmitDatas)}
                        >
                            FINALIZAR
                        </StyledButton>
                    </div>
                </StyledForm>
            </>
        )
    }

    return (
        <StyledContainerForResponsive className="margin-header mb-4" maxWidth="xl">
            <StyledTitleCharcoal variant="h4">Pré-Agendamento</StyledTitleCharcoal>
            {renderFormChavesDeAcesso()}
            {renderFormVolumesESkus()}
            {renderJustifyVolumeSku()}
            <hr />
            {renderDateForm()}
        </StyledContainerForResponsive>
    );

}