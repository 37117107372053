import { Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Panvel from "../../../assets/img/logo_blue.png";
import { AgendamentoContext } from "../../../contexts/Agendamento/AgendamentoContext";
import { IResponseAgendamento, ITicket } from "../../../types/Agendamentos/agendamento.d";
import { formatDateToBRL } from "../../../utils/date";
import { formatCNPJ } from "../../../utils/strings";
import { StyledContainerForResponsive } from "../../UI/containers";
import { StyledBorder, StyledDivButtons, StyledCol, StyledFlexRow, StyledLogo, StyledTicketLine } from "./styles";
import { useNavigate } from "react-router-dom";
import Print from "@mui/icons-material/Print";
import { StyledArrowBackIcon } from "../../UI/buttons";
import { EnumTurno } from "./turnoEnum";

const enumEnderecosCds = {
    1021: 'Av. Industrial Belgraf, 865 | Eldorado do Sul - RS',
    1017: 'Rod. Contorno Leste - BR 116, 8849 | São José dos Pinhais - PR',
    1007: 'Av. Talma Rodrigues Ribeiro, 147, Galpão 3º | Serra - ES'
}

const enumCds = {
    1021: 'RS - 1021',
    1017: 'PR - 1017',
    1007: 'ES - 1007'
}

const TicketPage: React.FC = () => {
    const { ticket, setTicket } = useContext(AgendamentoContext);
    const [ticketData, setTicketData] = useState<ITicket>();
    const navigate = useNavigate();

    useEffect(() => {
        (() => setTicketData({} as ITicket))();
    }, [])

    useEffect(() => {
        if (ticket?.ticket) {
            setTicketData(ticket);
            setTicket({} as IResponseAgendamento)
        }
    }, [ticket])

    interface IDataValues {
        label: string,
        value: string | number | undefined
    }

    const dadosDaTransportadora: IDataValues[] = [
        {
            label: 'MOTORISTA:',
            value: ticketData?.motorista
        },
        {
            label: 'TELEFONE:',
            value: ticketData?.telefone
        },
        {
            label: 'CNPJ:',
            value: ticketData?.cnpj ? formatCNPJ(ticketData?.cnpj) : ''
        },
        {
            label: 'TRANSPORTADORA:',
            value: ticketData?.nomeTransportadora && ticketData?.nomeTransportadora?.length > 40 ? ticketData?.nomeTransportadora?.slice(0, 34).concat('...') : ticketData?.nomeTransportadora
        }
    ]

    const dadosDeEntrega: IDataValues[] = [
        {
            label: 'CD DESTINO DIMED:',
            value: enumCds?.[ticketData?.cd as keyof typeof enumCds]
        },
        {
            label: 'ENDEREÇO:',
            value: enumEnderecosCds?.[ticketData?.cd as keyof typeof enumEnderecosCds]
        }
    ]

    const contatos: IDataValues[] = [
        {
            label: 'Recebimento RS:',
            value: '(51) 3481-9824'
        },
        {
            label: 'Recebimento PR:',
            value: '(41) 3380-7301'
        },
        {
            label: 'Recebimento ES:',
            value: '(27) 99612-5397'
        }
    ]

    const enderecosCds: IEnderecoEHorariosDeEntrega[] = [
        {
            uf: 'Paraná',
            endereco: enumEnderecosCds[1017],
            horarios: [
                '6H00 AM - ENTREGAS ACIMA DE 1.000 CAIXAS',
                '6H00 AM - ENTREGAS ACIMA DE 100 SKUS',
                '8H00 AM - 12H00 PM - ENTREGAS ABAIXO DE 1.000 CAIXAS',
                '8H00 AM - 12H00 PM ENTREGAS ABAIXO DE 100 SKUS'
            ]
        },
        {
            uf: 'Rio Grande do Sul',
            endereco: enumEnderecosCds[1021],
            horarios: [
                '8H00 AM - 12H00 PM - TODAS ENTREGAS'
            ]
        },
        {
            uf: 'Espírito Santo',
            endereco: enumEnderecosCds[1007],
            horarios: [
                '8H00 AM - 12H00 PM - RECEBIMENTO POR ORDEM DE CHEGADA',
                'OBS: AGENDAMENTOS ACIMA DE 500 CAIXAS, DEVEM CHEGAR ATÉ AS 10HS.'
            ]
        }
    ]

    const mountHtmlContent = (list: IDataValues[]) => {
        return list?.map((item, index) => {
            return (
                <div className="content flex" key={`content-${index}`}>
                    <Typography fontSize={12} className="content__label p-1">{item.label}</Typography>
                    <Typography fontSize={12} className="content__value p-1">{item.value}</Typography>
                </div>
            )
        })
    }

    const renderLogo = () => {
        return (
            <StyledFlexRow>
                <StyledLogo src={Panvel} alt="Logo" />
            </StyledFlexRow>
        )
    }

    const renderHeader = () => {
        return (
            <StyledTicketLine>
                <StyledCol>
                    <Typography fontWeight={600} variant="subtitle2" className="text-center">TICKET: {ticketData?.ticket}</Typography>
                </StyledCol>
                <StyledCol>
                    <Typography fontWeight={600} variant="subtitle2" className="text-center">DATA: {formatDateToBRL(ticketData?.data)}</Typography>
                </StyledCol>
                <StyledCol><Typography fontWeight={600} variant="subtitle2" className="text-center">TURNO: {EnumTurno[ticketData?.turno as keyof typeof EnumTurno]}</Typography>
                </StyledCol>
            </StyledTicketLine>
        )
    }

    const renderDadosEntrega = () => {
        return (
            <React.Fragment key={`fragment-dados-entrega`}>
                <StyledFlexRow className="mb-2">
                    <StyledCol>
                        <Typography fontSize={18} fontWeight={600} className="text-center border-bottom">DADOS DE ENTREGA</Typography>
                    </StyledCol>
                    <StyledCol>
                        <Typography fontSize={18} fontWeight={600} className="text-center border-bottom">TRANSPORTADORA</Typography>
                    </StyledCol>
                </StyledFlexRow>
                <StyledFlexRow>
                    <StyledCol>
                        <div className="content">
                            {mountHtmlContent(dadosDeEntrega)}
                        </div>
                    </StyledCol>
                    <StyledCol>
                        <div className="content">
                            {mountHtmlContent(dadosDaTransportadora)}
                        </div>
                    </StyledCol>
                </StyledFlexRow>
                <StyledBorder />
            </React.Fragment>
        )
    }

    const renderEntrega = () => {
        return (
            <React.Fragment key={`fragment-entrega`}>
                <StyledFlexRow>
                    <StyledCol>
                        <Typography fontWeight={600} fontSize={18} className="text-left mt-2 mb-1">ENTREGA</Typography>
                        <div className="content flex col p-2">
                            <div className="content__label align-self-start">
                                <Typography fontWeight={600} fontSize={14}>Notas: {ticketData?.notas?.map((nota, index) => {
                                    return `${nota.notaFiscal}${index === ticketData?.notas?.length - 1 ? '.' : ', '}`
                                })}</Typography></div>

                        </div>
                    </StyledCol>
                </StyledFlexRow>
                <StyledFlexRow className="background-gray mt-3 mb-3">
                    <StyledCol><Typography fontSize={14} fontWeight={600}>Número de caixas: {ticketData?.volume}</Typography></StyledCol>
                    <StyledCol><Typography fontSize={14} fontWeight={600}>SKU'S: {ticketData?.quantidadeSku}</Typography></StyledCol>
                    <StyledCol><Typography fontSize={14} fontWeight={600}>QUANTIDADE DE NOTAS: {ticketData?.notas?.length}</Typography></StyledCol>
                </StyledFlexRow>
            </React.Fragment>
        )
    }

    interface IEnderecoEHorariosDeEntrega {
        uf: string,
        endereco: string,
        horarios: string[]
    }

    const createEnderecosHtml = (enderecos: IEnderecoEHorariosDeEntrega[]) => {
        return enderecos?.map((endereco, index) => {
            const horarios = endereco.horarios.map((horario, index) => {
                return (<Typography fontSize={12} key={`horario-${index}`}>{horario}</Typography>)
            })
            return (
                <React.Fragment key={`fragment-enderecoos-${index}`}>
                    <Typography variant="body1" key={`endereco-${index}`} fontWeight={600} className="text-left text-decoration-underline">{endereco.uf}</Typography>
                    <div className="content mb-3">
                        <Typography fontSize={14} fontWeight={600} className="pt-1 pb-1">{endereco.endereco}</Typography>
                        {horarios || <></>}
                    </div>
            </React.Fragment>)
        });
    }

    const renderRegras = () => {
        return (
            <React.Fragment key={`fragment-endereco-cds`}>
                <StyledFlexRow>
                    <StyledCol>
                        <Typography fontWeight={600} fontSize={18} className="text-left">REGRAS</Typography>
                    </StyledCol>
                </StyledFlexRow>
                <StyledFlexRow>
                    <StyledCol className="m-2">
                        <Typography className="mb-2 content__label">A APRESENTAÇÃO DO TICKET É OBRIGATORIA.</Typography>
                        {createEnderecosHtml(enderecosCds)}
                    </StyledCol>
                </StyledFlexRow>

            </React.Fragment>
        )
    }


    const renderContatos = () => {
        return (
            <StyledFlexRow className="mb-4">
                <StyledCol>
                    <Typography fontSize={18} fontWeight={600} variant="subtitle1" className="text-left">CONTATO</Typography>
                    <div className="content">
                        {mountHtmlContent(contatos)}
                    </div>
                </StyledCol>
            </StyledFlexRow>
        );
    }

    return (
        <StyledContainerForResponsive className="margin-header" maxWidth="xl">
            <StyledDivButtons>
            <StyledArrowBackIcon titleAccess='Voltar para horários agendados' onClick={() => navigate('/agendamentos')} />
                <Button
                    title="Imprimir ticket"
                    variant="contained"
                    color="warning"
                    onClick={() => window.print()}
                >
                    <Print />
                </Button>
            </StyledDivButtons>
            {renderLogo()}
            {renderHeader()}
            {renderDadosEntrega()}
            {renderEntrega()}
            {renderRegras()}
            {renderContatos()}
        </StyledContainerForResponsive>
    )
}

export default TicketPage;