import React, { useCallback, useContext, useEffect, useState } from "react";
import { AgendamentoContext } from "../../../../contexts/Agendamento/AgendamentoContext";
import { useCheckboxHook } from "../../../../hooks/useCheckboxHook";
import { INotaFiscal } from "../../../../types/Agendamentos/agendamento.d";
import { addLineKeyToArrayItems } from "../../../../utils/utils";
import { StyledButton } from "../../../UI/buttons";
import { enumMessageMotivoBloqueioNotaFiscal, enumMotivoBloqueioNotaFiscal, removeNotasFiscaisDuplicadas } from "../../service";
import {
    StyledBootstrapTable,
    StyledTrashDeleteButton
} from "../styles";
import { createNotaFiscalByKeyString } from "./service";
import { StyledTotalVolume } from "./styles";
import { toast } from "react-toastify";
import GenericModal from "../../../GenericModal";

function TableChavesDeAcesso() {
    const [open, setOpen] = useState(false);
    const [agendamentoParaDelete, setAgendamentoParaDelete] = useState<INotaFiscal>();
    const { listaDeChaves, listaDeNotasFiscais, setListaDeChaves, setListaDeNotasFiscais } = useContext(AgendamentoContext);
    const [notasFiscais, setNotasFiscais] = useState<INotaFiscal[]>([]);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [multipleSelectedItems, setMultipleSelectedItems] = useState<boolean>(false);

    const {
        selectedItems,
        isItemSelected,
        toggleItemSelection,
        selectAllItems,
        deselectAllItems,
        areAllItemsSelected,
    } = useCheckboxHook(checkedItems);

    useEffect(() => {
        setCheckedItems(listaDeChaves?.map((chave) => chave) || []);
        if (!listaDeNotasFiscais?.length) {
            criaListaNotasFiscais();
        } else {
            atualizaListaDeNotasFiscais();
        }
    }, [listaDeChaves])

    useEffect(() => {
        if (selectedItems.length > 1) {
            setMultipleSelectedItems(true)
        } else {
            setMultipleSelectedItems(false)
        }
    }, [selectedItems])

    useEffect(() => {
        setNotasFiscais(addLineKeyToArrayItems(listaDeNotasFiscais));
    }, [listaDeNotasFiscais])

    const removeSelectedItems = useCallback(() => {
        const updatedListaDeChaves = listaDeChaves.filter(chave => !selectedItems.includes(chave));
        setListaDeChaves(updatedListaDeChaves);
        deselectAllItems();
    }, [listaDeChaves, listaDeNotasFiscais, selectedItems, deselectAllItems]);


    const criaListaNotasFiscais = useCallback(() => {
        const listaDeNotas = addLineKeyToArrayItems(listaDeChaves?.map((chave) => createNotaFiscalByKeyString(chave)))
        setListaDeNotasFiscais(listaDeNotas);
        setNotasFiscais(listaDeNotas);
    }, [listaDeChaves])

    const atualizaListaDeNotasFiscais = useCallback(() => {
        const newListaDeNotas = listaDeChaves?.map((chave) => createNotaFiscalByKeyString(chave));
        const listaFiltrada = newListaDeNotas?.filter((nota) => !listaDeNotasFiscais?.find((notaFiscal) => notaFiscal?.chave === nota?.chave))
        const notasFiscais = removeNotasFiscaisDuplicadas(listaFiltrada, newListaDeNotas)
        setNotasFiscais(notasFiscais);
        setListaDeNotasFiscais(notasFiscais);
    }, [listaDeChaves])

    const onExcludeKey = () => {
        if (agendamentoParaDelete && !multipleSelectedItems) {
            const novaListaDeNotas = notasFiscais?.filter((nota) => (nota?.chave !== agendamentoParaDelete?.chave)) || [];
            const novaListaDeChaves = listaDeChaves?.filter((chave) => (chave !== agendamentoParaDelete?.chave)) || [];
            setListaDeNotasFiscais(novaListaDeNotas)
            setListaDeChaves(novaListaDeChaves)
            toast.success('Chave(s) deletada(s) com sucesso!')
            return;
        }
        removeSelectedItems();
        setMultipleSelectedItems(false);
        setCheckedItems([]);
        toast.success('Chave(s) deletada(s) com sucesso!')
    }

    return (
        <>
            <StyledButton
                color="warning"
                variant="contained"
                className="mt-2 mb-2"
                onClick={() => setOpen(true)}
                disabled={!selectedItems.length}
            >
                Deletar Chave(s) Selecionada(s) <StyledTrashDeleteButton className={`delete-checked ${!selectedItems.length ? 'disabled' : ''}`} id="delete-selected-button" />
            </StyledButton>
            {!notasFiscais?.length ? <></> :
                <StyledBootstrapTable id='keys-table' striped hover>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    title="Selecionar"
                                    type="checkbox"
                                    checked={areAllItemsSelected}
                                    onChange={() => areAllItemsSelected ? deselectAllItems() : selectAllItems()}
                                />
                            </th>
                            <th>Chave</th>
                            <th>Nota Fiscal</th>
                            <th>Número de caixas</th>
                            <th>Status</th>
                            <th>Ticket</th>
                        </tr>
                    </thead>
                    <tbody className="table-hover">
                        {notasFiscais?.length && notasFiscais?.map((item: INotaFiscal, index) => {
                            const isLastItem = index === notasFiscais.length - 1;
                            return (
                                <React.Fragment key={`fragment-${item.lineKey}`}>
                                    <tr id={`table-line-agendamento-${item.lineKey}`} key={`table-line-keys-${item.lineKey}`}>
                                        <td className="d-flex">
                                            <input
                                                title="Selecionar"
                                                type="checkbox"
                                                checked={isItemSelected(item.chave)}
                                                onChange={() => toggleItemSelection(item.chave)}
                                            />{' '}
                                            <StyledTrashDeleteButton
                                                id="delete-button"
                                                onClick={() => {
                                                    setAgendamentoParaDelete(item)
                                                    setOpen(true)
                                                }} />
                                        </td>
                                        <td>{item?.chave}</td>
                                        <td>{item?.notaFiscal}</td>
                                        <td>{item.volume ? item.volume : ''}</td>
                                        <td>{item?.motivoBloqueio ? enumMessageMotivoBloqueioNotaFiscal?.[item.motivoBloqueio as keyof typeof enumMotivoBloqueioNotaFiscal] : enumMessageMotivoBloqueioNotaFiscal?.['DEFAULT']}</td>
                                        <td>{item?.ticket}</td>
                                    </tr>
                                    {isLastItem && (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <StyledTotalVolume>Total:</StyledTotalVolume>
                                            <StyledTotalVolume>
                                                {notasFiscais?.reduce((acc, item) => {
                                                    return acc + (item?.volume || 0)
                                                }, 0)}
                                            </StyledTotalVolume>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </React.Fragment>

                            )
                        }) || <></>}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={6}></td>
                        </tr>
                    </tfoot>
                </StyledBootstrapTable>}
            <GenericModal
                type="alert"
                onClose={() => setOpen(false)}
                onConfirm={onExcludeKey}
                open={open}
                title="Deletar chave(s) selecionada(s)?"
                message="Você tem certeza que deseja deletar a(s) chave(s) selecionada(s)?"
            />
        </>
    );
}

export default TableChavesDeAcesso;