import FilterListIcon from '@material-ui/icons/FilterList';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PrintIcon from '@mui/icons-material/Print';
import { Checkbox, FormControl, IconButton, Input } from '@mui/material';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AgendamentoContext } from "../../../../contexts/Agendamento/AgendamentoContext";
import { AgendamentosState } from "../../../../store/ducks/agendamentos/types";
import { ApplicationState } from '../../../../store/index';
import { IResponseAgendamento, ITicketReduzido } from "../../../../types/Agendamentos/agendamento.d";
import { dateIsNotValidAgendamento, formatDateToBRL } from '../../../../utils/date';
import GenericModal from '../../../GenericModal';
import { StyledContainerForResponsive } from '../../../UI/containers';
import { StyledSearchIconFlex } from '../../../UI/icons';
import { StyledTitleCharcoal } from '../../../UI/titles';
import AdvancedSearch from '../AdvancedSearch';
import CustomPagination from '../Pagination';
import TableTicketComNota from '../TableTicketNotas';
import { linesWithPagination } from '../service';
import { StyledDivSearch, StyledFooterLine } from '../styles';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledContainerGrid, StyledDivNoContent, StyledGrid, StyledHeaderThead, StyledMenuItemFilter, StyledSelectFilter, StyledTableDeAgendamentos, StyledTableRow } from './styles';

function TableAgendamentos() {
    const dispatch = useDispatch();
    const [maxItemsPerPage, setMaxItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const valorMinimoPaginacao = 10;
    const { setTicket } = useContext(AgendamentoContext);
    const { loading, agendamentos, ticketsComNotas } = useSelector<ApplicationState, AgendamentosState>((state) => state?.agendamentos);
    const [listaAgendamentos, setListaAgendamentos] = useState<ITicketReduzido[]>([]);
    const [totalPaginas, setTotalPaginas] = useState<number>(0);
    const [ticketSelecionado, setTicketSelecionado] = useState<ITicketReduzido>({} as ITicketReduzido);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [filterCd, setFilterCd] = useState<string[]>(['1007-ES', '1017-PR', '1021-RS']);
    const selectRef = useRef<HTMLSelectElement>(null);
    const [isFilterClicked, setIsFilterClicked] = useState<boolean>(false);

    useEffect(() => {
        dispatch({
            type: "@agendamentos/GET_AGENDAMENTOS",
            onSuccess: (ticketReduzidoList: any) => {
                setListaAgendamentos(linesWithPagination(ticketReduzidoList, currentPage, maxItemsPerPage));
            },
            onError: () => { }
        })
    }, []);

    useEffect(() => {
        const filteredAgendamentos = agendamentos?.filter((agendamento: ITicketReduzido) => agendamento?.ticket?.toString().includes(searchValue));
        setListaAgendamentos(linesWithPagination(filteredAgendamentos, currentPage, maxItemsPerPage));
        setTotalPaginas(Math.ceil(filteredAgendamentos?.length / maxItemsPerPage));
    }, [searchValue])

    useEffect(() => {
        setListaAgendamentos(linesWithPagination(agendamentos, currentPage, maxItemsPerPage));
        setTotalPaginas(Math.ceil(agendamentos?.length / maxItemsPerPage));
    }, [agendamentos, maxItemsPerPage])

    useEffect(() => {
        setCurrentPage(0);
        setListaAgendamentos(linesWithPagination(agendamentos, 0, maxItemsPerPage));
    }, [maxItemsPerPage])

    useEffect(() => {
        setCurrentPage(0);
        if (!filterCd?.length && isFilterClicked || filterCd?.length && isFilterClicked && !agendamentos?.some((agendamento: ITicketReduzido) => filterCd.includes(agendamento?.cd))) {
            toast.info("Nenhum agendamento encontrado para o(s) CD(s) selecionado(s).");
        }
        setListaAgendamentos(linesWithPagination(agendamentos?.filter((agendamento: ITicketReduzido) => filterCd.includes(agendamento?.cd)), 0, maxItemsPerPage));
    }, [filterCd])

    const changePageValue = (numeroDaPagina: number) => {
        if (numeroDaPagina === currentPage) return
        setCurrentPage(numeroDaPagina);
        setListaAgendamentos(linesWithPagination(agendamentos, numeroDaPagina, maxItemsPerPage));
    }

    const getTicketByCode = (ticket: number, navigate: () => void) => {
        dispatch({
            type: "@agendamentos/GET_TICKET_BY_CODE",
            payload: { ticket },
            onSuccess: (ticket: IResponseAgendamento) => {
                setTicket(ticket)
                navigate()
            },
        })
    }

    const handleDeleteAgendamento = (agendamento: ITicketReduzido) => {
        dispatch({
            type: "@agendamentos/DELETE_TICKET_BY_CODE",
            payload: { ticket: agendamento?.ticket },
            onSuccess: () => toast.success(`Ticket n° ${agendamento.ticket} excluído com sucesso!`),
            onError: () => { }
        })
    }

    const renderSearchInput = () => {
        return (
            <StyledDivSearch key="search-ticket-div">
                <StyledSearchIconFlex onClick={() => {
                    document.getElementById("search-input")?.focus();
                }} id="search-ticket" />
                <Input
                    id="search-input"
                    placeholder="Pesquisar por ticket"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    data-cy="search-ticket"
                />
            </StyledDivSearch>
        )
    }

    const searchInputElement = useMemo(() => renderSearchInput(), [searchValue]); 

    const renderHeaderTable = (index: number) => {
        return index === 0
            ? (<StyledHeaderThead cy-data="table-header-agendamentos">
                <StyledGrid className="header-item flex-grow-1" item xs={1.2}>
                    <AlarmAddIcon titleAccess='Novo Agendamento' onClick={() => navigate('/agendamento')} />
                </StyledGrid>
                <StyledGrid className="header-item flex-grow-1" item xs={2.7}>Ticket</StyledGrid>
                <StyledGrid className="header-item flex-grow-1" item xs={2.2}>Número de caixas</StyledGrid>
                <StyledGrid className="header-item flex-grow-1" item xs={2.65}>Data</StyledGrid>
                <StyledGrid className="header-item flex-grow-1" item xs={2.4}>CD Destino {renderFilterCd(filterCdParams)}</StyledGrid>
                <StyledGrid className="header-item flex-grow-1" item xs={2.45}>Turno</StyledGrid>
                <StyledGrid className="header-item flex-grow-1" item xs={0.3}></StyledGrid>
            </StyledHeaderThead>)
            : <></>
    }

    const renderTableFooter = () => {
        return (
            <div className="footer-table flex">
                {!agendamentos?.length && renderHeaderTable(0)}
                {!agendamentos?.length && (<StyledDivNoContent>Nenhum registro localizado.</StyledDivNoContent>)}
                {(agendamentos?.length && agendamentos?.length >= valorMinimoPaginacao)
                    ? <StyledFooterLine>
                        <label className="p-2">Máximo de linhas por pagina:</label>
                        <select id="max-per-page-select" title="Máximo de linhas por pagina" value={maxItemsPerPage} onChange={(e) => setMaxItemsPerPage(Number(e.target.value))}>
                            <option value="10">10</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                        </select>
                    </StyledFooterLine> : <></>}
            </div>)
    }

    const renderNotasDoTicket = (ticket: ITicketReduzido) => {
        return (
            <StyledAccordionDetails id={`panel-${ticket?.ticket}-content`}>
                <TableTicketComNota ticket={ticket} />
            </StyledAccordionDetails>
        )
    }

    const renderControlButtonsArea = (agendamento: ITicketReduzido) => {
        return (
            <StyledGrid
                className="control-table"
                item
                xs={1.3}
            >
                <DeleteOutlineIcon
                    fillOpacity={dateIsNotValidAgendamento(agendamento?.dataAgendamento) ? 0.3 : 1}
                    onClick={() => {
                        if (!dateIsNotValidAgendamento(agendamento?.dataAgendamento)) {
                            setOpen(true);
                            setTicketSelecionado(agendamento);
                        }
                    }} className="delete-button" />
                <ModeEditIcon
                    fillOpacity={dateIsNotValidAgendamento(agendamento?.dataAgendamento) ? 0.3 : 1}
                    className="edit-button"
                    onClick={() => !dateIsNotValidAgendamento(agendamento?.dataAgendamento) && getTicketByCode(agendamento?.ticket, () => navigate("/agendamento"))}
                />
                <PrintIcon
                    className="print-button"
                    onClick={() => getTicketByCode(agendamento?.ticket, () => navigate("/ticket"))}
                />
            </StyledGrid>
        )
    }

    const renderInformacoesTicket = (agendamento: ITicketReduzido) => {
        return (
            <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${agendamento?.ticket}-content`}
                id={`panel-${agendamento?.ticket}-header`}
                key={`panel-${agendamento?.ticket}-header`}
            >
                <StyledTableRow>
                    <StyledGrid item hidden xs={0}></StyledGrid>
                    <StyledGrid item xs={2.9}>{agendamento?.ticket}</StyledGrid>
                    <StyledGrid item xs={3}>{agendamento?.volumes}</StyledGrid>
                    <StyledGrid item xs={2.9}>{formatDateToBRL(agendamento?.dataAgendamento)}</StyledGrid>
                    <StyledGrid item xs={3}>{agendamento?.cd}</StyledGrid>
                    <StyledGrid item xs={3}>{agendamento?.turno}</StyledGrid>
                </StyledTableRow>
            </StyledAccordionSummary>
        )
    }

    const renderDropdownRow = (agendamento: ITicketReduzido) => {
        return (
            <StyledAccordion data-cy="ticket-dropdown" onClick={!ticketsComNotas || !ticketsComNotas?.filter(res => res.ticket === agendamento.ticket)?.length ? () => getTicketByCode(agendamento.ticket, () => { }) : () => { }} id={`accordion-${agendamento?.ticket}`}>
                {renderInformacoesTicket(agendamento)}
                {renderNotasDoTicket(agendamento)}
            </StyledAccordion>
        )
    }

    const renderTableRow = (agendamento: ITicketReduzido) => {
        return (
            <StyledTableRow
                className="ticket-row"
                data-cy="ticket-row"
                id={`table-line-agendamento-${agendamento?.ticket}`}
                key={`table-line-keys-${agendamento?.ticket}`}
            >
                {renderControlButtonsArea(agendamento)}
                {renderDropdownRow(agendamento)}
            </StyledTableRow>
        )
    }

    const renderLine = (agendamento: ITicketReduzido, index: number) => {
        return (<Fragment key={`table-${index}`}>
            {renderHeaderTable(index)}
            {renderTableRow(agendamento)}
        </Fragment>)
    }

    const renderTable = () => {
        return (
            <StyledTableDeAgendamentos id='table-agendamentos'>
                <StyledContainerGrid container spacing={0}>
                    {listaAgendamentos?.map((agendamento: ITicketReduzido, index: number) => renderLine(agendamento, index))}
                </StyledContainerGrid>
                {renderTableFooter()}
            </StyledTableDeAgendamentos>
        )
    }

    const renderPagination = () => {
        if (agendamentos?.length > valorMinimoPaginacao) {
            return (<CustomPagination
                currentPage={currentPage}
                changePageValue={changePageValue}
                totalPaginas={totalPaginas}
                key="pagination-agendamentos"
            />)
        }
    }

    const filterCdParams = {
        label: 'Centro de Distribuição',
        key: 'filtro-cd',
        type: 'select',
        options: [
            {
                label: '1007-ES',
                value: '1007-ES',
                checked: filterCd.includes('1007-ES'),
            },
            {
                label: '1017-PR',
                value: '1017-PR',
                checked: filterCd.includes('1017-PR'),
            },
            {
                label: '1021-RS',
                value: '1021-RS',
                checked: filterCd.includes('1021-RS'),
            }
        ],
        value: filterCd,
    }


    const renderFilterCd = (filter: any) => {
        return (
            <FormControl>
                <IconButton style={{ position: 'relative', top: 0, marginTop: '-8px', color: '#fff' }}>
                    <FilterListIcon />
                </IconButton>
                <StyledSelectFilter
                    ref={selectRef}
                    value={filterCd}
                    multiple
                    onChange={(e) => {
                        setIsFilterClicked(true);
                        setFilterCd(e.target.value as string[])
                    }}
                    displayEmpty
                    renderValue={() => <></>}
                    MenuProps={{ PaperProps: {} }}
                >
                    {filter?.options?.map((opt: any) => (
                        <StyledMenuItemFilter key={`menu-item-${opt.value}`} value={opt.value}>
                            <Checkbox checked={opt.checked} />
                            {opt.label}
                        </StyledMenuItemFilter>
                    ))}
                </StyledSelectFilter>
            </FormControl>
        );
    };


    return (
        <StyledContainerForResponsive className="margin-header" maxWidth="xl">
            <StyledTitleCharcoal className="text-start w-100" variant="h4" component="h4" gutterBottom>Horários Agendados</StyledTitleCharcoal>
            <AdvancedSearch childrens={[searchInputElement]} />
            {renderTable()}
            {renderPagination()}
            <GenericModal
                type="alert"
                onClose={() => setOpen(false)}
                onConfirm={() => handleDeleteAgendamento(ticketSelecionado)}
                open={open}
                title="Excluir Agendamento"
                message="Você tem certeza que deseja excluir esse agendamento?"
            />
        </StyledContainerForResponsive>
    );
}

export default TableAgendamentos;