export const EnumStatusPreAgendamento = {
  "APROVADO": "APROVADO",
  "AGUARDANDO_APROVACAO": "AGUARDANDO_APROVACAO",
  "REPROVADO": "REPROVADO",
  "AGENDADO": "AGENDADO",
}

export const EnumCD = {
  1021: "1021-RS",
  1017: "1017-PR",
  1007: "1007-ES"
}